import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Building a working model of Gondwana dispersion has reached a new horizon. A
paper  summarising many of the results from the past years of work on
understanding the Indian Ocean  has been accepted for publication and should
appear in a Special Publication of the Geological  Society later this year. The
rotation parameters for achiveing the fit and the relative movements  of Africa,
Antarctica and India over time are included in the paper. A key contribution in
the last 12  months has been the new understanding that has come from
introducing mid-ocean ridges into the  model, moving at half the angular
velocity of their conjugate coasts. `}</p>
    <p>{`On the one hand, this shows the precision with which mid-ocean ridges are
central and self replicating over time. On the other hand, when ridge jumps
occur it has proved possible to  understand the restrictions provided by the
record of fracture zones on the ocean floor on just how  this can have happened
in each case. Contrary to what some authors still (attempt to) publish,  there
is little room left for 'blue sky' thinking in the geometric evolution of the
oceans. A new  animation illustrating the latest model with fracture zones,
mid-ocean ridges and the output of  large igneous provinces is in preparation
and will be posted on the Gondwana page of this website  very soon. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      